(function (window) {

    "use strict";

    window.Bark = window.Bark || {};
    window.Bark.consts = window.Bark.consts || {};

    /**
     * Failed to get combined updates
     * @see Bark Seller Notifications
     */
    window.Bark.consts['ERR_COMBINED_UPDATES'] = 'BSNE1';
    /**
     * Failed to apply notifications
     * @see Bark Seller Notifications
     */
    window.Bark.consts['ERR_APPLY_NOTIFICATIONS'] = 'BSNE2';
    /**
     * The screen width at which to assume that the display is in mobile mode
     */
    window.Bark.consts['MOBILE_WIDTH_THRESHOLD'] = 1024;
    /**
     * The screen width at which to assume that the display is in mobile mode
     */
    window.Bark.consts['MOBILE_MODAL_THRESHOLD'] = 640;
    /**
     * What 1em translates to
     */
    window.Bark.consts['ONE_EM_IN_PX'] = 14;
    /**
     * Project has been closed
     */
    window.Bark.consts['PROJECT_STATUS_CLOSED'] = 2;
    /**
     * The seller is on a free plan
     */
    window.Bark.consts['SELLER_PLAN_TYPE_FREE'] = 3;
    /**
     * The onkeypress keycode for the enter key
     */
    window.Bark.consts['KEY_CODE_ENTER'] = 13;
    /**
     * The error code for when a project has too many responses
     */
    window.Bark.consts['EX_PROJECT_TOO_MANY_RESPONSES'] = 90101;
    /**
     * Exception code for exceptions for when a stripe customer already exists
     */
    window.Bark.consts['EX_USER_CUSTOMER_EXISTS'] = 90301;
    /**
     * Exception code for when a user is created but the email address already exists
     */
    window.Bark.consts['EX_EMAIL_EXISTS_IN_USERS'] = 90302;

    /**
     * Exception code for when a user is created but the email address already exists
     */
    window.Bark.consts['EX_EMAIL_EXISTS_IN_BUYERS'] = 90303;

    /**
     * Exception code for when a user is created but the email address already exists
     */
    window.Bark.consts['EX_EMAIL_EXISTS_IN_SELLERS'] = 90304;

    /**
     * Exception code for exceptions for when a payment gets declined
     */
    window.Bark.consts['EX_PAYMENT_DECLINED'] = 90201;

    /**
     * Exception code for exceptions for when a payment of a credit pack returns less credits
     * than the Bark is worth
     */
    window.Bark.consts['EX_CREDITS_PURCHASE_LOW'] = 90202;

    /**
     * Add a comment to this line
     * WebSocket Chat server URL
     */
    window.Bark.consts['WS_URL'] = 'https://ws.bark.com';

    /**
     * Definition of S3 buckets and their distros
     */
    window.Bark.consts['S3_BUCKET_LOCATIONS'] = [];

    /**
     * Google api - client id
     */
    window.Bark.consts['GOOGLE_CLIENTID'] = '574843737653-nnmb3nkvuuh9cfdiknh65fquo4fr4g0j.apps.googleusercontent.com';

}(window));
